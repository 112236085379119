// Overrides and custom styles for Video.js library
#video-js {
  position: static;
  display: none; // Hide until library inited
  height: auto;
  width: 100%;
  max-width: none;

  &.video-ready {
    display: inline;
  }

  // Remove play button while playing
  &.vjs-playing {
    .vjs-big-play-button:before {
      opacity: 0;
    }
  }

  &:hover {
    .vjs-big-play-button:before {
      color: $orange;
    }
  }

  // Overrides for play button
  .vjs-big-play-button {
    display: none;
    left: 50%;
    top: 50%;
    height: 105px;
    width: 96px;
    transform: translate(-50%, -50%);
    background: none;
    border: none;

    @media(min-width: $tablet-portrait) {
      display: block !important;
    }

    &:before {
      position: relative;
      top: 0;
      left: 0;
      font-family: "icomoon";
      content: "\e900";
      z-index: 0;
      line-height: 1.1;
      font-size: 96px !important;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7);
      transition: all 0.2s;

      @include breakpoint($desktop) {
        font-size: 128px !important; // Fixes IE issue
      }
    }
  }

  .vjs-control-bar {
    display: none;
    bottom: 48px;
    width: 250px;
    margin: 0 auto;
    background: none;

    @media(min-width: $tablet-portrait) {
      display: flex !important; // Keep controls always visible
      width: 310px;
    }

    .vjs-progress-control {
      order: 1;
      flex: 0 0 180px;

      @media(min-width: $tablet-portrait) {
        flex: 0 0 275px;
      }

      .vjs-progress-holder {
        height: 8px;
        color: rgba(216, 216, 216, 0.5);
      }

      .vjs-load-progress {
        display: none;
      }

      .vjs-play-progress {
        height: 8px;
        background: rgba(255, 255, 255, 0.5);

        &:before {
          top: -2px;
          font-size: 0.9rem !important;
          color: $white;
        }
      }
    }

    .vjs-play-control,
    .vjs-volume-menu-button {
      top: -6px;
      font-size: 0.9rem;
      color: rgba(216, 216, 216, 0.5);

      &:hover:before{
        text-shadow: none;
        color: $orange;
      }

      &:focus:before,
      &:focus {
        text-shadow: none;
      }
    }

    .vjs-play-control {
      order: 2;
    }

    .vjs-volume-menu-button {
      order: 4;
      left: 14px;

      &:before {
        width: 0;
      }

      .vjs-menu {
        left: 2.5em;
      }

      .vjs-volume-level {
        background: rgba(216, 216, 216, 0.5);

        &:before {
          color: $white;
        }
      }
    }

    .video-share-link {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -80px;
      padding: 10px 5px;
      font-size: 0.9rem;
      color: white;
      background-color: rgba(115, 133, 159, 0.5);
      border: 1px solid white;
      cursor: pointer;

      div {
        margin-top: 5px;
        font-size: 0.7rem;
        text-align: center;
      }
    }

    .video-share-icon {
      order: 3;
      font-size: 1.5rem;
      color: rgba(216, 216, 216, 0.5);
      line-height: 1.3;
      cursor: pointer;

      &:before {
        font-family: "icomoon";
        content: "\ea7d";
      }

      &:hover:before {
        color: $orange;
      }
    }

    .copy-message {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -115px;
      padding: 5px;
      color: white;
      font-size: 0.9rem;
      background-color: rgba(115, 133, 159, 0.5);
      border: 1px solid white;

      div {
        position: absolute;
        width: 5px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
        border: 5px solid transparent;
        border-top: 5px solid white;
      }
    }
  }
}

// Style native webkit video controls - because the video is larger than the viewport at all times, the default controls get appened to the bottom which makes it un-usable.
// Fix this by positioning them
.flexslider video::-webkit-media-controls-enclosure {
  position: absolute;
  bottom: 48px;
  width: 320px;
}

// Hacks to remove the download option which only appears in the Chrome. Part of the Shadow DOM and only certain styles can reach it. Style both videos (drawer and slider)
video::-webkit-media-controls {
  overflow: hidden !important
}

video::-webkit-media-controls-enclosure {
  width: calc(100% + 32px);
  margin-left: auto;
}