//generics
html.js fieldset {
  border-style: solid;
  border-color: $orange;
}

.page-search {
  .pager a,
  .title a,
  .fieldset-legend a {
    transition: color 0.2s;

    &:hover {
      color: $orange;
    }
  }

  .tabs-primary {
    margin-top: 10px;
  }

  #content {
    margin: 0 16px;

  @include breakpoint($desktop) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .search-form {
    margin: $large-margin-buffer 0;
    .form-item {
      display:inline-block;
    }

    .form-text {
      padding: 4px 4px 1px;
    }

    #edit-basic input[name="op"] {
      margin:0 0 1em 0;
      display:block;
      @include breakpoint($tablet-portrait) {
        margin:0 0 0 1em;
        display:initial;
      }
    }
  }

  .search-results {
    margin-top: 0;
  }

  .search-results li {
    margin-bottom: $large-margin-buffer;

    &:not(:first-child) {
      border-top: 1px solid $orange;
    }
  }

  .pager {
    position:initial;
    background-color:$white;

    .pager-current {
      color: $orange
    }
  }

  .search-result {
    overflow: hidden;

    strong {
      color: $orange;
      font-weight: 400;
    }

    .title,
    .search-snippet-info {
      padding-left: 0;

      @include breakpoint($tablet-portrait) {
        @include span(6 of 12 last);
      }
    }

    .file-image,
    .file-video {
      @include breakpoint($tablet-portrait) {
        @include span(6 of 12);
      }
    }
  }
}
