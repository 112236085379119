// @file
// Custom sass mixins
//
// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax.
  }
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

@mixin responsive-gutters {
  @include breakpoint($mobile-only) {
    margin-right: $page-margin-mobile;
    margin-left: $page-margin-mobile;
  }

  @include breakpoint($tablet-portrait-only) {
    margin-right: $page-margin-tablet-portrait;
    margin-left: $page-margin-tablet-portrait;
  }

  @include breakpoint($tablet-landscape-only) {
    margin-right: $page-margin-tablet-landscape;
    margin-left: $page-margin-tablet-landscape;
  }

  @include breakpoint($desktop) {
    margin-right: auto;
    margin-left: auto;
  }
}

@mixin box-shadow-generator {
  box-shadow: inset 0 -90px 100px -60px #000000;
}