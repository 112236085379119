//Overrides for jQuery mmenu mobile menu
#mm-primary-menu {
  background-color: $orange;
  color: white;

  a {
    color: white;
  }

  a::after {
    border-color: white;
  }

  a::before {
    border-color: white;
  }

  .block-search {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    form {
      display: block;
      padding: 0 20px;
    }
    .form-type-textfield {
      display: block;
      position: relative;
      margin: 0;
    }
    .form-text {
      width: 100%;
      padding: 10px 48px 6px 16px;
      border: none;
      border-radius: 2px;
      color: $grey;
      &:focus {
        outline: none;
      }
    }
    .form-submit {
      display: none;
    }
  }

  .mm-navbar {
    border: none;
    top: 85px;
  }

  .fa-search {
    color: rgba(0,0,0,0.6);
    position: absolute;
    right: 0;
    top: 3px;
    transform: scaleX(-1);
    font-family: 'icomoon';
    font-size: 29px;

    &:before {
      content: '\e607';
    }
  }

  .close-menu-icon {
    font-family: "icomoon";
    font-style: normal;
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 1.5rem;
    cursor: pointer;

    &:before {
      content: '\e60c';
    }
  }

  .mm-first a::before {
    border-left: 0;
  }

  .mm-listview {
    margin-top: 55px;
  }

  .mm-listview > li::after {
    border-color: rgba(255,255,255,0.5);
    right: 17px;
  }

  .menu__link {
    text-transform: uppercase;
  }

  .menu__item.last:before {
    position: absolute;
    bottom: -65px;
    right: 20px;
    z-index: 50;
    font-family: 'icomoon';
    font-size: 3rem;
    line-height: 1;
    content: '\e608';
    color: $white;
  }
}