footer {
  background-image: url('../images/footer_image_medium.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 64%;
  overflow: hidden;
  color: $white;
  font-weight: 300;

  @include breakpoint($tablet-landscape) {
    display: block;
  }

  .inner-footer-wrapper {
    @include responsive-gutters;
    overflow: hidden;

    @include breakpoint($mobile-landscape) {
      text-align: left;
    }

    @include breakpoint($desktop) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  @include breakpoint($mobile-only) {
    .newsletter-area {
      display: inline-block;
    }

    form {
      overflow: hidden;
    }
  }

  h5 {
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0;

    @include breakpoint($mobile-only) {
      margin-top: 2px;
      text-align: left;
      line-height: 1;
    }

    @include breakpoint($tablet-landscape) {
      font-size: 1rem;
    }
  }

  p {
    margin-top: 0;
    font-size: 0.8rem;

    @include breakpoint($tablet-landscape) {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }

  ul {
    overflow: hidden;
    padding: 0;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 0.9rem;

    @include breakpoint($mobile-landscape) {
      font-size: 0.7rem;
      margin: 1rem 0 0.2rem 0;

    }

    @include breakpoint($tablet-portrait) {
      font-size: 1rem;
    }

    .menu__item {
      display: block;
      float: left;
      padding-left: 10px;
      margin-left: 6px;
      position: relative;
      &:before {
        position: absolute;
        content: "|";
        left: 0;
      }
      &:first-child {
        padding-left: 0;
        margin-left: 0;
        &:before {
          content: "";
        }
      }
    }

    a.menu__link {
      display: block;
      text-decoration: none;
      color: $white;
    }
  }

  .footer-menu-area {
    @include breakpoint($mobile-landscape) {
      @include span(6 of 12);
    }

    @include breakpoint($tablet-landscape) {
      @include span(5 of 12);
    }

    p {
      margin-bottom: 0;
      color: $white;

      @include breakpoint($tablet-landscape) {
        margin-bottom: 20px;
      }
    }
  }

  .newsletter-area {
    overflow: hidden;
    // Font size 0 removes spacing between inline elements, allowing them to sit next to each other. Must define font size in children to compensate.
    font-size: 0;

    @include breakpoint($mobile-only) {
      margin-bottom: 10px;
    }

    @include breakpoint($mobile-landscape) {
      @include span(6 of 12 last);
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @include breakpoint($desktop) {
      @include span(4 of 12 last);
      margin-top: 1rem;
    }

    label {
      display: none;
    }

    form {
      @include breakpoint($mobile-only) {
        margin-top: 5px
      }
    }

    .form-type-textfield {
      float: left;
      width: 66.666%;
      input {
        width: 100%;
        padding: 10px 14px 6px;
        margin-right: 0;
        border-radius: 0;
        font-weight: 300;
        font-size: 0.9rem;
        border: none;
        outline: none;

        @include breakpoint($tablet-landscape) {
          font-size: 1rem;
        }
      }
    }

    .form-submit {
      width: 33.333%;
      padding: 10px 14px 6px;
      border-radius: 0;
      font-size: 14px;
      opacity: 1 !important; //Button was turning transparent on iOS mobile, this prevents it.

      @include breakpoint($tablet-landscape) {
        font-size: 1rem;
      }
    }
  }

  .block-menu-block {
    line-height: 1;
    margin: 0;

    @include breakpoint($tablet-portrait) {
      margin: 22px 0 0;
    }

    a.active {
      color: $white;
    }
  }

  .ajax-progress,
  .ajax-progress .throbber {
    position: absolute;
  }

  #cc-modal-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .cc-modal-overlay {
      position: absolute;
      z-index: 13;
      top: 0;
      bottom: -50px;
      left: 0;
      right: 0;
      background-color: rgba(0 , 0, 0, 0.6);
    }

    .cc-modal {
      position: fixed;
      z-index: 14;
      width: 80%;
      top: 25%;
      left: 10%;
      text-align: center;
      background-color: $white;
      color: black;

      @include breakpoint($tablet-portrait) {
        width: 50%;
        left: 25%;
        text-align: left;
      }

      img {
        display: block;
      }

      h2, p {
        padding: 0 20px;
      }

      h2 {
        margin-top: 20px;
      }

      p {
        margin-bottom: 20px;
        line-height: 1rem;
      }

      .large-close-btn {
        margin: 0 auto 20px;
        background-color: $orange;
        color: $white;
        font-size: 1rem;
        display: inline-block;
        padding: 5px 25px;
        cursor: pointer;
        transition: background-color, 0.2s;

        @include breakpoint($tablet-portrait) {
          float: right;
          margin: 0 20px 20px;
        }

        &:hover {
          background-color: $hover-orange;
        }
      }

      .small-close-btn {
        display: none;

        @include breakpoint($mobile-landscape) {
          display: block;
        }

        font-family: 'icomoon';
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: $orange;
        color: $white;
        font-size: 2rem;
        line-height: 2rem;
        cursor: pointer;
        transition: background-color, 0.2s;

        &:before {
          content: '\e60c';
        }

        &:hover {
          background-color: $hover-orange;
        }
      }
    }

    //Both cc-modal states share styling, just use different images
    .cc-modal .cc-image {
      position: relative;

      &:before {
        content: " ";
        position: absolute;
        left: 50%;
        top: 50%;
        width: 80%;
        height: 80%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transform: translate(-50%, -50%);
      }
    }

    //Succcess State, show check
    .cc-modal.cc-submission-success .cc-image:before {
      background-image: url(../images/email_modal_check.png);
    }
    //Fail state, show X
    .cc-modal.cc-submission-fail .cc-image:before {
      background-image: url(../images/email_modal_x.png);
    }
  }
}

// Style for social menu, technically in bottom region but connected to footer
.region-bottom {

  .block-menu {
    padding: 1rem 0;
    overflow: hidden;
    @extend .max-width;
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: center;
    @include breakpoint($mobile-landscape) {
      text-align: right;
    }
  }

  li.menu__item {
    //@include span(2 of 12);
    display: inline-block;
    margin-left: 5px;
    list-style: none;
    @include box-sizing(border-box);

    &:hover {
      a {
       color: $orange;
      }
    }
    &.first {
      margin-left: 0;
      a {
        @include breakpoint($tablet-portrait) {
          padding-left: 0;
        }
      }
    }
    a {
      text-align: center;
      font-size: 0.7rem;
      color: $solid-grey;
      display: block;
      text-decoration: none;
      transition: color 0.2s;

      @include breakpoint($mobile-landscape) {
        font-size: 0.8rem;
        padding: 0.5rem;
        margin: 0;
      }
    }
  }
}