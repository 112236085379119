.node-project.view-mode-full .drawer {

  @media(min-width: $tablet-landscape) {
    position: fixed;
    top: 0;
    left: 100%;
    width: 90%;
    z-index: 14;
    height: 100%;
    background: $white;
    overflow: scroll;
    font-size: 1.2rem;
    font-weight: 300;
    -webkit-overflow-scrolling: touch;
    @include transition(all 0.3s ease-in-out);
  }

  .right {
    @include box-sizing(border-box);

    @media(min-width: $tablet-landscape) {
      float: right;
      width: 75%;
      padding: 0 50px 0 40px;
    }
  }

  .left {
    @include breakpoint($tablet-landscape) {
      float: left;
      width: 25%;
    }
    .field-label {
      margin: 20px 0 8px;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
    .field-item,
    .field-item p {
      font-size: 0.75rem;
      margin-top: 3px;
      line-height: 1.5em;
      &:first-child {
        margin-top: 0;
      }
    }
    .field-item p {
      margin: 0 0 0.5rem 0;
    }
    .icon-download-wrapper {
      display: flex;
      align-items: center;
    }
    .download-icon {
      flex: 0 0 16px;
      height: 16px;
    }
    .file-download-link {
      flex: 0 0 auto;
      margin: 0 0 0 5px;
      font-family: $din;
    }
    .field-name-field-section-title .field-item {
      margin: 20px 0 8px;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: uppercase;
    }

    //Match link styling if just a span (if no URL, it will be outputted as span)
    .field-name-field-publication-links {
      span {
        color: #aaa;
      }
    }

    .field-name-field-project-sketch {
      margin-top: 20px;
    }

    .field-name-field-awards {
     .field-name-field-year {
       display: inline-block;
       float: left;
       margin-right: 10px;
     }
    }

    .field-collection-container,
    .field-collection-container p,
    .field-collection-container .field-item,
    .field-name-field-additional-sections .field-item:nth-child(n+2) {
      margin: 0;
    }

    .field-name-field-additional-sections .field-name-field-section-title {
      margin: 20px 0 8px 0;
    }

    .field-collection-view-links,
    .action-links-field-collection-add {
      display: none;
    }
  }

  .node-title {
    margin: 0;
    line-height: 1;
    color: $off-black;

    @include breakpoint($mobile-only) {
      padding-right: 50px;
    }
  }

  .drawer-inner-container {
    position: relative;
    overflow: hidden;
    padding: 12px $page-margin-mobile 90px;

    @include breakpoint($tablet-portrait) {
      padding: $large-margin-buffer $page-margin-tablet-portrait 90px;
    }
  }

  .close-project-drawer {
    display: none;

    @media(min-width: $tablet-landscape) {
      font-family: "icomoon";
      position: absolute;
      top: 56px;
      right: $page-margin-tablet-portrait;
      color: $white;
      background-color: $orange;
      font-size: 3rem;
      line-height: 3rem;
      cursor: pointer;
      transition: background-color 0.2s;

      &:before {
        content: '\e60c';
      }

      &:hover {
        background-color: $hover-orange;
      }
    }
  }

  .field-label {
    font-weight: 300;
    color: $off-black;
  }

  .field-name-field-project-pdf,
  .project-description {

    .field-item {
     font-size: 1rem;
    }
  }

  .field-name-field-project-awards h2 {
    margin-top: 4px;
  }

  .field-name-field-project-pdf {
    margin-top: 26px;
  }

  .project-images {
    @include breakpoint($tablet-portrait) {
      margin-top: $large-margin-buffer;
    }
  }

  .field-collection-container {
    border: none;
    margin-top: 2rem;

    @include breakpoint($tablet-portrait) {
      display: block;
    }
  }

  .field-name-field-project-location {
    margin-top: 2px;
    font-size: 1rem;
  }

  .field-collection-container {
    margin: 0 0 20px 0;

    .field-collection-view {
      margin: 0;
      padding: 0;
    }

    .slider-caption {
      position: absolute;
      left: 16px;
      bottom: 8px;
      z-index: 26; // This gets it over the slider + slide overlay on hover

      p {
        margin: 0;
        color: $white;
      }
    }

    .field-collection-view {
      border: none;
    }
  }

  .field-name-field-project-media .field-item {
    margin-bottom: gutter();

    img {
      display: block;
      width: 100%;
    }

    .field-item:last-child {
      margin-bottom: $large-margin-buffer;
    }
  }

  .field-name-field-project-pdf {
    position: relative;

    &:hover {
      .download-icon {
        opacity: 0.8;
      }
    }

    a {
      position: relative;
      vertical-align: top;
      padding-left: 30px;
      font-size: 1rem;
    }

    .download-icon {
      width: auto;
      position: absolute;
      transition: opacity 0.2s;
    }
  }

  video {
    width: 100%;
    color: red;
  }

  .pager {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding: 25px $page-margin-mobile 0;
    height: 65px;
    background-color: rgb(36,36,36);
    text-align: left;

    @include breakpoint($tablet-portrait) {
      padding: 25px $page-margin-tablet-portrait 0;
    }

    .name {
      text-decoration: none;
      position: absolute;
      color: $white;
      cursor: pointer;
      transition: color 0.2s;
      font-size: 1rem;
      line-height: 22.5px;

      &:hover {
        color: $orange;
      }
    }

    .prev {
      left: 30px;
      display: none;

      @include breakpoint($tablet-portrait) {
        display: block;
      }
    }

    .next {
      right: 30px;
    }

    .next .name {
      right: 35px;
    }

    .prev img {
      cursor: pointer;
      left: 0;
      top: -2px;
      transform: scaleX(-1);
    }

    .prev .name {
      left: 35px;
    }

    .next img {
      cursor: pointer;
      right: 0;
      top: -2px;
    }

    .inner-pager-wrapper {
      position: relative;
      vertical-align: middle;
    }

    .next-arrow {
      position: absolute;
    }
  }
}
