.porch-house--container {
  border-top: 1px solid rgb(151,151,151);
  * {
    box-sizing: border-box;
  }
  h2 {
    font-size: 1rem;
    color: $orange;
    font-weight: 300;
    margin-bottom: 0;
    @include breakpoint($mobile-only) {
      margin-top: 0;
    }
  }

  &.porch-house--container--concept .porch-house--grid-row--blocks .porch-house--grid-item--content {
    text-align: center;
  }
}
.page-node-441 .porch-house--container > img {
  margin-bottom: 150px;
}
// Controls the image layout on "Porches" page.
.porch-house--grid-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(40, 3vw);
  grid-gap: 20px;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 10;
  }

  img:nth-of-type(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 10;
    grid-row-end: 20;
  }

  img:nth-of-type(3) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 20;
    grid-row-end: 34;
  }

  img:nth-of-type(4) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 20;
    grid-row-end: 34;
  }

  img:nth-of-type(5) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 34;
    grid-row-end: 40;
  }

  img:nth-of-type(6) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 34;
    grid-row-end: 40;
  }
}

$gutter: 20px;

.porch-house--grid-row {
  @include breakpoint($tablet-portrait) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.porch-house--grid-row--center-items {
  @include breakpoint($tablet-portrait) {
    align-items: center;
  }

}

.porch-house--grid-item {
  margin-top: $gutter;
  @include breakpoint($tablet-portrait) {
    margin-top: 0;
  }
}
.porch-house--grid-item--content {
  i.fa.fa-long-arrow-down {
    color: #eec16f;
    font-size: 36px;
    margin-top: 10px;
  }
}

.porch-house--grid-item--half {
  @include breakpoint($tablet-portrait) {
    flex: 1 1 50%;
  }
}

.porch-house--grid-item--third {
  @include breakpoint($tablet-portrait) {
    flex: 1 1 33.333%;
  }
}

.porch-house--grid-item--column img {
  margin-top: $gutter;
  &:first-child {
    margin-top: 0;
  }
}

.porch-house--text {
  @include breakpoint($tablet-portrait) {
    padding-right: 40px;
  }
}

.porch-house--content-links--container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @include breakpoint($tablet-portrait) {
    display: none;
    margin: 0;
  }
  a {
    padding: 5px;
    color: $orange;
    &:before,
    &:after {
      transform: translateY(-1px);
      font-family: FontAwesome;
      font-size: 12px;
    }
  }
}

.porch-house--content-links--link-previous {
  margin-right: auto;
  a {
    &:before {
      margin-right: 5px;
      content: "\f053";
    }
  }
}

.porch-house--content-links--link-next {
  margin-left: auto;
  a {
    padding-right: 0;
    &:after {
      margin-left: 5px;
      content: "\f054";
    }
  }
}

//.view-projects .porch-house--content-links--container {
//  @extend .max-width;
//}

// Controls layout on the "Rooms" page.
.porch-house--container--rooms {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 25px 0;
  align-items: center;
}

.porch-house-menu-wrapper-desktop {
  background: white;
}

.porch-house--process-family {
  margin-top: 85px;
}
