.flexslider,
.slider-container {
  position: relative;
  margin-bottom: $large-margin-buffer;
  border: none;

  .flex-control-nav {
    display: none;
    bottom: 5px;
    z-index: 12;

    @include breakpoint($tablet-portrait) {
      display: block;
    }
  }

  .flex-control-nav li {
    margin: 0 3px;
  }

  .flex-control-nav li a {
    background: rgba(255, 255, 255, 0.4);
  }

  .flex-control-nav li a.flex-active {
    background: rgba(255, 255, 255, 1);
  }

  .file-image img {
    @include breakpoint($desktop) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .field-type-file .field-item { //height is set by JS to viewport. VH units remain to help element occupy space on load.
    position: relative;
    box-sizing: border-box;
    height: 100vh;
    overflow: hidden;
    margin-top: -46px;

    @include breakpoint($tablet-portrait) {
      margin-top: -64px;
    }
  }

  .contextual-links-region {
    position: static;
  }

  .flex-direction-nav .flex-prev,
  .flex-next {
    overflow: visible;
    display: none;
    font-size: 0;
    line-height: 1;
    padding: 10px;

    &:before {
      font-family: "icomoon";
      text-shadow: none;
    }

    @include breakpoint($tablet-portrait) {
      display: block;
    }
  }

  &:hover .flex-direction-nav .flex-next {
    right: 24px;
    opacity: 1;
  }

  &:hover .flex-direction-nav .flex-prev {
    left: 24px;
    opacity: 1;
  }

  .flex-direction-nav .flex-prev:before,
  .flex-next:before {
    color: $white;
  }

  .flex-direction-nav .flex-prev {
    left: 24px;
    z-index: 3;
    outline: none;

    &:before {
      content: "\e609";
    }

    @include breakpoint($tablet-portrait) {
      left: 48px !important;
    }
  }

  .flex-direction-nav .flex-next {
    right: 24px;
    z-index: 3;
    outline: none;

    &:before {
      content: "\e60a";
    }

    @include breakpoint($tablet-portrait) {
      right: 48px !important;
    }
  }
}
//Change arrow hover effect on all sliders
.flexslider,
.slider-container {
  .flex-prev,
  .flex-next {
    &:before {
      transition: all 0.2s;
    }

    &:hover {
      &:before {
        color: $orange;
      }
    }
  }
}

//Change arrow strike area on homepage & project caraousels only
.node-type-project,
body.front {
  .flexslider,
  .slider-container {
    .flex-prev,
    .flex-next {
      top: 45%;
    }

    .flex-prev {
      padding: 75px 175px 75px 48px;
      left: 0 !important;
    }

    .flex-next {
      padding: 75px 48px 75px 100px;
      right: 0 !important;
    }
  }
}

.flexslider.field-name-field-page-hero-media {
  margin-bottom: 0;
  .file-image img {
    @include breakpoint($desktop) {
      position: static;
    }
  }
}
