.list-view {
  color: $black;

  .hover-overlay {
    display: none;
  }

  .node-profile,
  .node-project {
    h2,
    div.field {
      transition: color 0.2s;
    }

    &:hover {
      h2,
      div.field {
        color: $orange;
      }
    }
  }

  .view-mode-tile {

    header {

      @include breakpoint($tablet-portrait) {
        margin-left: 0;
      }
    }

    img {
      display: none;
    }
    
    .node-title {
      position: static;
      display: block;
      font-size: 1rem;
      color: $grey;
      font-weight: 400;
    }

    .field-name-field-profile-accreditation {
      color: $grey;
    }
  }
}

//Project list view is different, displays only one column and not all uppercase
.page-taxonomy-term {
  .list-view {
    .view-content .views-row {
      @include with-layout($tile-layout) {
        float: none;
        margin-top: gutter();
        width: 100%;

        .node-title span {
          text-transform: capitalize;
        }
      }
    }
  }
}