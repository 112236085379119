//Styling for profile draw pop-in-out. On mobile it takes up more screen than vs tablet and +
.profile-drawer {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 100%;
  width: 90%;
  height: 100%;
  overflow-y: scroll;
  background-color: $white;
  -webkit-overflow-scrolling: touch;
  @include transition(all 0.3s ease-in-out);

  &.show {
    left: 10%;
  }

  @include breakpoint($mobile-landscape) {
    left: 100%;
    width: 70%;

    @include transition(all 0.3s ease-in-out);
    &.show {
      left: 30%;
    }
  }
}

//Styling for profile node in full view mode
.node-topic.view-mode-full,
.node-profile.view-mode-full {
  position: relative;
  padding-bottom: 65px; // making space for absolutely positioned pager
  font-weight: 300;

  h1 {
    margin-top: 0;
    color: $off-black;
  }

  header {
    overflow: hidden;
  }

  .field-name-field-profile-banner-image {
    display: none;
    position: relative;

    @include breakpoint($tablet-portrait) {
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0.5;
      @include box-shadow-generator;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
    }

    .content {
      box-sizing: border-box;
      padding: 25% 0;
      width: 100%;
    }

  }

  div.bottom {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: $profile-drawer-gutter-tablet $profile-drawer-gutter-mobile 65px;

    @include breakpoint($tablet-portrait) {
      padding-left: $profile-drawer-gutter-tablet;
      padding-right: $profile-drawer-gutter-tablet;
    }

    .field-name-field-file-image-title-text {
      display: none;
    }
  }

  .field-items .file-image {
    max-height: 450px;
    overflow: hidden;
  }

  .field-name-field-profile-profile-image {
    overflow: hidden;
    margin-bottom: 20px;

    @include breakpoint($tablet-portrait) {
      float: left;
      width: 40%;
      @include box-sizing(border-box);
      padding-right: 40px;
      margin-bottom: gutter();
    }
  }

  .field-name-field-profile-profile-image img {
    width: 100%;
    display: block;

    @include breakpoint($tablet-portrait) {
      width: auto;
    }
  }

  .file-image .captions {
    position: absolute;
    bottom: 11px;
    left: $profile-drawer-gutter-mobile;
    z-index: 5;
    @include clearfix;
    @include breakpoint($tablet-portrait) {
      left: $profile-drawer-gutter-tablet;
    }
  }

  .field-name-field-file-image-title-text,
  .field-name-field-image-link {
    float: left;
  }


  .field-name-field-image-link a {
    color: $white;
  }

  .text-area {
    overflow: hidden;
  }

  .close-drawer-icon:before,
  .close-drawer-icon-bottom:before{
    font-family: "icomoon";
    content: "\e60c";
    font-size: 3rem;
    background-color: $orange;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .close-drawer-icon:hover:before,
  .close-drawer-icon-bottom:hover:before {
    background-color: $hover-orange;
  }

  .close-drawer-icon,
  .close-drawer-icon-bottom {
    position: absolute;
    top: 44px;
    right: 20px;
    @include breakpoint($tablet-portrait) {
      right: 40px;
      top: 35px;
    }
  }

  .close-drawer-icon {
    display: none;
    @include breakpoint($tablet-portrait) {
      display: block;
    }
  }

  .close-drawer-icon-bottom {
    @include breakpoint($tablet-portrait) {
      display: none;
    }
  }

  .text-area .node-title {
    margin: 0;
    text-transform: uppercase;
  }

  .text-area .field-name-field-profile-role {
    float: left;
  }

  .field-name-field-title {
    float: left;
  }

  h2 {
    color: $off-black;
    margin-bottom: 0;
    line-height: 1.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;

    @include breakpoint($tablet-portrait) {
      margin-top: 0;
    }
  }

  .field-name-field-profile-accreditation {
    display: inline-block;
  }

  .field-type-text-with-summary {
    line-height: 1.8rem;
    clear: both;

    p {
      margin-bottom: $medium-margin-buffer;
    }
  }

  .related-media-title {
    margin: $medium-margin-buffer 0 4px;
  }

  .field-type-email {
    margin: $medium-margin-buffer 0;

    .field-label {
      margin-bottom: 4px;
    }
  }

  .view-related-media a {
    padding: 0 !important;
  }
}

.profile-drawer .pager {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  padding: 25px $page-margin-mobile 0;
  height: 65px;
  background-color: rgb(36,36,36);
  text-align: left;

  @include breakpoint($tablet-portrait) {
    padding: 25px $page-margin-tablet-portrait 0;
  }

  .name {
    text-decoration: none;
    position: absolute;
    color: $white;
    cursor: pointer;
    transition: color 0.2s;
    font-size: 1rem;
    line-height: 22.5px;

    &:hover {
      color: $orange;
    }
  }

  .prev {
    left: 30px;
    display: none;

    @include breakpoint($tablet-portrait) {
      display: block;
    }
  }

  .next {
    right: 30px;
  }

  .next .name {
    right: 35px;
  }

  .prev img {
    cursor: pointer;
    left: 0;
    top: -2px;
    transform: scaleX(-1);
  }

  .prev .name {
    left: 35px;
  }

  .next img {
    cursor: pointer;
    right: 0;
    top: -2px;
  }

  .inner-pager-wrapper {
    position: relative;
    vertical-align: middle;
  }

  .next-arrow {
    position: absolute;
  }
}

//Eliminate any previous pagination only for Ted Flato's drawer
.node-18.node-profile.view-mode-full {
  .prev {
    display: none !important;
  }
}

.profile-contact__trigger {
  cursor: pointer;
  transition: color 0.2s;
}

.profile-contact__trigger:hover {
  color: $hover-orange !important;
}

.profile-contact {
  display: none;

  input,
  textarea {
    padding: 10px;
  }

  input:focus,
  textarea:focus {
    outline-color: $orange;
  }
}

.profile-contact .form-submit {
  border-radius: 0;
  padding: 10px 14px 6px;
  font-size: 1rem;
}

.node-topic {
  .profile-drawer .node-title {
      width: 80%;

      @media(min-width: $tablet-portrait) {
        width: 100%;
      }
    }

  .banner-container {
    @media(min-width: $tablet-portrait) {
      height: 70px;
    }
  }

  .field-name-field-image-link {
    float: none !important;
    margin: -10px 0 30px 0;
    font-size: 0.83rem;
    font-weight: bold;
  }
}
