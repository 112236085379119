#colorbox {
  height: 360px !important;

  #cboxWrapper {
    border-radius: 0;
  }

  #cboxMiddleLeft,
  #cboxMiddleRight,
  #cboxTopCenter,
  #cboxTopLeft,
  #cboxTopRight,
  #cboxBottomLeft,
  #cboxBottomCenter,
  #cboxBottomRight {
    width: 0;
    height: 0;
  }
}

#cboxOverlay {
  background: rgba(255,255,255, 0.7);
  box-sizing: border-box;
  border: 25px solid rgba(0,0,0, 0.8);
  opacity: 1 !important;

  &:before {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    font-family: "icomoon";
    content: "\e607";
    color: $orange;
  }
}

