.node-type-video {
  .tabs-primary {
    margin-top: 80px;
  }
}

.node-video.view-mode-full {
  .content-wrapper {
    position: relative;
    overflow: hidden;
    margin-top: 46px;
    padding-bottom: 50px;

    @include breakpoint($tablet-portrait) {
      margin-top: 66px;
    }
  }

  .field-name-body {
    margin-bottom: 5rem;
  }

  .field-type-text-with-summary {
    @include breakpoint($tablet-portrait) {
      @include span(8 of 12);
    }
  }

  .next-arrow {
    position: absolute;
    left: 0;
    bottom: 30px;
    height: 12px;
    width: 12px;
    transform: scaleX(-1);
    cursor: pointer;
  }

  a {
    position: absolute;
    left: 15px;
    bottom: 8px;
    padding: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s;

    &:hover {
      color: $orange;
    }
  }
}
