//remove hover overlay when on list view for tile view modes
.profile-views-container.list-view {
  .view-profiles .view-mode-tile:before {
    box-shadow: none;
  }

  .node-project .file-image:before {
    box-shadow: none
  }
}

.profile-views-container {
  margin-bottom: $large-margin-buffer;
  .ajax-progress {
    display: block;
    text-align: center;
  }
  .throbber {
    display: inline-block;
    float: none;
    width: 45px;
    background: transparent url(../images/ajax-loader.gif) no-repeat 0px center;
  }
  .message {
    padding: 0;
    color: $grey;
  }

  .view-profiles .view-mode-tile:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @include box-shadow-generator;
  }

  h3 {
    border-top: 1px solid $grey;
    padding-top: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  .view-profiles {
    overflow: hidden;
    margin-bottom: 3rem;
  }

  //CSS for switching view modes with buttons
  .toggle-view {
    overflow: hidden;

    @include breakpoint($desktop) {
      margin: 0 auto;
      max-width: 1200px;
    }

    h3 {
      color: black;
    }

    .button-wrapper {
      float: right;
    }

    .tile,
    .list {
      font-family: "icomoon";
      color: $grey;
      border: none;
      background-color: white;
      outline: none;
      font-size: 1rem;

      &:hover {
        color: $orange;
      }
    }

    .list {
      padding-right: 0;
      padding-left: 0;
    }

    .grid {
      padding-right: 0;
      padding-left: 0;
    }

    .tile:before {
      content: "\e603";
    }

    .list:before {
      content: "\e604";
    }
  }
}

.view-mode-tile {
  position: relative;
  cursor: pointer;

  //Only want these fields to show up on history/sustainability, so hidden here
  .field-name-field-file-image-title-text,
  .field-name-field-image-link {
    display: none;
  }

  &:hover,
  .hover {
    .hover-overlay {
      opacity:0.8;
    }
  }

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    display: block;
  }

  .node-title,
  .field-name-field-profile-accreditation {
    font-size: 16px;
    color: $white;
  }

  .node-title {
    z-index: 3;
    position: absolute;
    bottom: 4px;
    padding: 0 8px;
    margin: 0;
    font-weight: 300;
    line-height: 1em;
    max-height: 2em;
    overflow: hidden;
    text-transform: none;
  }

  .field-name-field-profile-accreditation {
    display: inline-block;
  }

  .hover-overlay {
    @include breakpoint($tablet-portrait-only) {
      height: 50%;
    }

    z-index: 2;
    position: absolute;
    height: 35%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $orange;
    opacity: 0;
    transition: opacity 0.2s;

    .view-overlay-text {
      position: absolute;
      font-size: 16px;
      top: 3px;
      left: 8px;
      color: $white;
      font-weight: 300;
    }
  }
}
// Override for topics where the hover consumes 100% of the image.
.view-topics .view-mode-tile .hover-overlay {
  display: none;

  @media(min-width: $tablet-landscape) {
    display: block;
    height: 100%;
  }
}

//Specific styles for video tile
.node-video.view-mode-tile,
.node-research-publication.view-mode-tile,
.node-brochure .field-name-field-image,
.node-type-research-publication .field-name-field-image-research,
.node-brochure.view-mode-tile {
  font-family: 'icomoon';

  &:hover {
    &:before {
      color: $orange;
    }
  }

  &:before {
    position: absolute;
    z-index: 2;
    content: "\e614";
    color: rgba(255, 255, 255, 0.7);
    font-size: 10rem;
    top: 50%;
    left: 50%;
    transition: color 0.2s;
    transform: translate(-50%, -50%);
  }

  header:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @include box-shadow-generator;
  }

  a {
    position: absolute;
    z-index: 7;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-decoration: none;
  }

  .node-title {
    z-index: 3;
    font-weight: 300;
    bottom: 4px;
    left: 9px;
    font-family: $din;
  }
}

.node-research-publication.view-mode-tile,
.node-type-research-publication .field-name-field-image-research,
.node-brochure .field-name-field-image,
.node-brochure.view-mode-tile, {
  &:before {
    content: "\e903";
    font-size: 7rem;
  }
}

// Override for topics
.node-topic.view-mode-tile:hover {
  .hover-overlay {
    z-index: 2;
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $orange;
    opacity: 1;
    transition: opacity 0.2s;
  }
}

.node-topic.view-mode-tile .view-overlay-text {
  padding: 10px;
  top: 0;
  left: 0;
}

.node-topic.view-mode-tile .node__title {
  padding-left: 10px;
}
