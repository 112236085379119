body {
  @include container();
  font-weight: 300;
  &.drawer-is-open {
    overflow: hidden;
  }
}

html {
  color: $black;
}

ul {
  padding: 0;
  margin: 0.5rem 0;
}

li {
  list-style-type: none;
}

h1, h2 {
  font-weight: normal;
  text-transform: uppercase;
}

h1 {
  font-size: 1.25rem;
  line-height: 2rem;
  margin: $large-margin-buffer 0 $small-margin-buffer;
}

h2 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 36px 0 4px;
}

p {
  font-size: 1rem;
  line-height: 1.65rem;
  margin: 0 0 30px;
  color: $black;
}

a {
  text-decoration: none;
  color: $grey;
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: $orange;
  }
}

.max-width {
  @include responsive-gutters();
  @include breakpoint($desktop) {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@include breakpoint($desktop) {
  .narrow-max-width {
    max-width: 900px;
    margin: 0 auto;
  }
}

//contrains hover menu from flowing off screen
.page {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#main {
  flex: 1 1 auto;
}

//overlay for project and profile drawer
.body-overlay {
  position: fixed;
  z-index: 14;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}

//Syling for quotes - both inline and sidebar
.field-collection-container {
  border: none;
  overflow: hidden;
  margin: 0 0 $large-margin-buffer;
}

.field-name-field-sidebar-quote,
.field-name-field-quote {
  font-weight: 300;
  font-style: italic;
  position: relative;
  border-left: 2px solid $orange;
  padding-left: 1rem;
  margin-left: 45px;
  line-height: 28px;
  @include box-sizing(border-box);

  .quote-icon {
    left: -45px;
    position: absolute;
    top: 0;
    width: 26px !important;
    height: 24px;
    display: block;
  }

  .field-type-text-long {
    -webkit-font-smoothing: antialiased;
    font-family: "ACasionPro", serif;
  }

  .field-item {
    margin-bottom: 0;
  }

  .field-collection-view {
    margin: 0;
    padding: 0;
  }

  .field-name-field-quote-author {
    font-style: normal;
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.2;
  }

  .field-name-field-quote-subtitle {
    margin-top: 4px;
    font-style: normal;
    line-height: 0.8rem;
    font-size: 0.8rem;
  }
}

//Main quote quote specific styling
.field-name-field-quote .field-type-text-long {
  line-height: 2.3rem;
  font-size: 1.7rem;
}

//Sidebar quote specific styling
.field-name-field-sidebar-quote .field-type-text-long {
  font-size: 1.5rem;
}

//Project quote specific styling
.node-project .field-type-text-long {
  font-size: 1.4rem;
  line-height: 1.9rem;
}

//Displays video play icon if hero image links to a video
.field-name-field-page-hero-media.is-video {
  cursor: pointer;
  cursor: hand;
}

.field-name-field-page-hero-media.is-video {
  &:hover {
    a::before {
      background-color: $hover-orange;
    }

    .field-type-link-field {
      opacity: 1;
      bottom: 10px;
    }
  }

  a::before {
    position: absolute;
    bottom: 0;
    right: 48px;
    z-index: 2;
    content: "\e614";
    font-family: "icomoon";
    background-color: $orange;
    color: $white;
    font-size: 3rem;
    line-height: 48px;
    cursor: pointer !important;
    cursor: hand;
    transition: background-color 0.2s;
  }

  .field-type-link-field  {
    display: none; //No hover on mobile, so don't allow until tablet-portrait
    position: absolute !important;
    z-index: 11;
    bottom: 0;
    right: 105px;
    //content: "Our Story";
    opacity: 0;
    color: $white;
    transition: all 0.2s;

    @include breakpoint($tablet-portrait) {
      display: block;
    }

    .field-items {
      position: static !important;
    }
  }
}

.clearfix:after {
  content: " ";
}

//Active class for view toggle selector
.active-view-button {
  color: $orange !important;
}

.profile-drawer,
.drawer {
  .field-name-body {
    margin-top: 20px;
  }
}

a:focus {
  outline: none;
}

//Special class to affect iframe in admin mode for news
.cke_editable p {
  margin-bottom: 0;
}

.the-dogrun {
  #main {
    max-width: 1200px;
    margin: 0 auto;

    @media(min-width: $tablet-portrait) {
      display: grid;
      grid-template-columns: minmax(300px, 60%) minmax(300px, 40%);
      grid-template-areas:
        'category title'
        'header header'
        'blog sidebar';
    }
  }

  .category-title {
    display: none;

    @media(min-width: $tablet-portrait) {
      display: block;
      grid-area: category;
      margin-left: 48px;
      color: $black;
      font-size: 1.5em;
      font-weight: 500;
    }

    @media(min-width: $desktop) {
      margin-left: 0;
    }
  }

  .title-container {
    display: none;

    @media(min-width: $tablet-portrait) {
      display: block;
      grid-area: title;
      margin-bottom: 20px;
      padding-left: 50px;
    }
  }

  .title-container__title {
    margin-bottom: 0;
    color: $black;
    font-size: 1.9rem;
    font-weight: 500;
  }

  .title-container__subtitle {
    margin-top: 0;
    letter-spacing: 0.6px;
  }

  .mobile-blog-menu {
    margin: 0 auto 25px auto;
    padding: 0 20px;

    @media(min-width: $tablet-portrait) {
      display: none;
    }
  }

  .mobile-blog-menu__dropdown {
    display: none;

    &.open {
      display: block;
    }
  }

  .mobile-blog-menu__category-link {
    position: relative;
    text-align: center;
    display: block;
    padding: 10px 5px 6px 5px;
    background: $orange;
    color: $white;
    font-size: 19px;
    font-weight: 400;
  }

  .plus {
    position: absolute;
    right: 10px;
    bottom: 50%;
    transform: translateY(50%);
    width: 20px;
    height: 20px;
  }

  .plus-vertical {
    position: relative;
    background-color: $white;
    width: 2px;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .plus-horizontal {
    position: relative;
    background-color: $white;
    width: 100%;
    height: 2px;
    bottom: 50%;
    transform: translateY(-50%);
  }

  .mobile-blog-menu__sub-selector {
    display: block;
    position: relative;
    border-bottom: 1px solid #ddd;

    :after {
      content: '';
      border-right: 3px solid #aaa;
      border-bottom: 3px solid #aaa;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: auto;
      position: absolute;
      top: 15px;
      right: 10px;
      transform: rotate(-45deg);
    }

    a {
      display: block;
      padding: 12px 5px 7px 5px;
      font-size: 19px;
      font-weight: 400;
      color: #aaa;
    }
  }

  #content {
    @media(min-width: $tablet-portrait) {
      grid-area: blog;
    }

    @media(min-width: $desktop) {
      padding-right: 50px;
    }
  }

  .blog-sidebar {
    display: none;

    @media(min-width: $tablet-portrait) {
      display: block;
      grid-area: sidebar;
      padding-left: 50px;
      border-left: 2px solid $orange;
    }

    h3 {
      margin: 30px 0 10px 0;
      color: #aaaaaa;
      font-size: 19px;
      font-weight: 400;
    }

    .sub-section {
      margin-bottom: 30px;
    }
  }

  .category-links {
    font-size: 19px;
    font-weight: 400;
  }

  .category-link {
    position: relative;
    width: 50%;
    display: block;
    padding: 8px 5px 4px 35px;
    margin-bottom: 5px;
    background: $orange;
    color: $white;
  }

  .category-link:before {
    content: '';
    position: absolute;
    background-color: $white;
    height: 13px;
    width: 2px;
    left: 15px;
    top: 12px;
  }

  .category-link:after {
    content: '';
    position: absolute;
    background-color: $white;
    width: 14px;
    height: 2px;
    left: 9px;
    top: 17px;
  }

  #custom-search-blocks-form-1 {
    position: relative;
    width: 75%;

    label {
      position: absolute;
      color: #aaaaaa;
      top: 2px;
      left: 2px;
      font-weight: 300;
      font-size: 14px;
    }

    input[type="text"] {
      padding-left: 50px;
      border: none;
      border-bottom: 1px solid #aaaaaa;
    }

    .form-submit {
      display: none;
    }
  }
}

.page-the-dogrun-archive {
  #main {
    margin: 0;

    @media(min-width: $tablet-portrait) {
      margin: 0 auto;
    }
  }

  .view-blog-archives {
    margin-bottom: 50px;

    @media(min-width: $tablet-portrait) {
      margin-bottom: 0;
    }
  }

  #content .view-blog-archives .view-content .item-list {
    li {
      display: block;
      position: relative;
      border-bottom: 1px solid #ddd;

      :after {
        content: '';
        border-right: 3px solid #aaa;
        border-bottom: 3px solid #aaa;
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: auto;
        position: absolute;
        top: 15px;
        right: 10px;
        transform: rotate(-45deg);
      }
    }

    a {
      display: block;
      padding: 12px 5px 7px 5px;
      font-size: 19px;
      font-weight: 400;
      color: #aaa;
    }
  }
}
