.node-type-page {
  .field-name-field-page-hero-media {
    .file {
      @include breakpoint($mobile-only) {
        padding-bottom: 75%;
      }

      img,
      video {
        @include breakpoint($mobile-only) {
          display: block;
          width: auto;
          position: absolute;
          top: 0;
          left: 50%;
          @include translateX(-50%);
          max-width: none ;
          height: 100% ;
        }
      }
      video::-webkit-media-controls-enclosure {
        display: none !important;
      }
    }

    .file-video {
      @include breakpoint($tablet-portrait) {
        padding-bottom: 41%; // this value matches the hero aspect ratio
        position: relative;
        video {
          width: 100%;
          display: block;
          position: absolute;
          bottom: -10%;
        }
      }
    }
  }
}

.node-page,
.taxonomy-term {
  img {
    width: 100%;
    display: block;
  }

  .content-wrapper {
    overflow: hidden;
  }

  .field-type-text-with-summary,
  .taxonomy-term-description {
    @include breakpoint($tablet-portrait) {
      @include span(8 of 12);
    }
  }

  .taxonomy-term-description {
    margin-top: 0;
  }

  .inner-max-width {
    @include breakpoint($desktop) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .field-collection-container {
    @include breakpoint($tablet-portrait) {
      transform: translateY(2px);
    }
  }
}

.narrow-max-width {
  //@include responsive-gutters;
  @include breakpoint($desktop) {
    max-width: 900px;
  }
}

// embedded quotes
.field-name-body {
  .max-width {
    @include breakpoint($tablet-landscape-and-below) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .field-collection-container {
    margin-top: $large-margin-buffer;
    @include breakpoint($tablet-landscape-and-below) {
      padding-left: 0;
    }
    @include breakpoint($desktop) {
      max-width: 900px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

//history page, just for the side-text
.node-6 {
  .awards .side-text p {
    margin-bottom: 0;

    @include breakpoint($mobile-only) {
      margin-top: $large-margin-buffer;
    }
  }
}

//Specific styling for history/sustainability page
.node-6,
.node-38 {

  .leed-listing {

    h2 {
      //clear: both;
    }

    ul {
      overflow: hidden;

      li {
        line-height: 28px;

        @include breakpoint($mobile-landscape) {
          width: 45%;
        }
      }

      li:nth-child(even) {
        @include breakpoint($mobile-landscape) {
          float: right;
          clear: right;
        }
      }

      li:nth-child(odd) {
        @include breakpoint($mobile-landscape) {
          float: left;
          clear: left;
        }
      }
    }
  }

  // These pages seem unique in their centered ness.
  .field-name-body {
    float: none;
    width: 100%;
    margin: 0;
  }

  // This pulls the header to the narrow text
  header {
    @extend .narrow-max-width;
  }

  .field-items .large-max-width {
    @extend .narrow-max-width;
    @include breakpoint($desktop) {
      max-width: 1200px;
    }
  }

  .side-view .award-listing {
    color: $grey;
    margin-bottom: 1rem;
  }

  .media-element-container {
    position: relative;
    .field-name-field-file-image-title-text,
    .field-name-field-image-link {
      position: absolute;
      bottom: 8px;
      left: 16px;
      color: $white;
      font-weight: 300;
      a {
        color: $white;
      }
    }
  }

  .image-box {
    max-width: 800px;
    overflow: hidden;
    margin: 0 auto 30px auto;

    @include breakpoint($tablet-portrait) {
      height: 527px;
    }

    div {
      box-sizing: border-box;

      @include breakpoint($tablet-portrait) {
        height: 100%;
      }
    }

    img {
      width: 100%;

      @include breakpoint($tablet-portrait) {
        height: 100%;
      }
    }

    .media {
      height: 50%;
    }

    > .media {
      width: 50%;
      height: 100%;
      float: left;
      padding-right: 10px;
    }

    .stacked-images {
      width: 50%;
      float: left;

      .media:first-of-type {
        padding-bottom: 5px;
      }

      .media:last-of-type {
        padding-top: 5px;
      }
    }

    .field-name-field-image-link {
      height: auto;
    }
  }

  .awards {
    overflow: hidden;

    @include breakpoint($tablet-portrait) {
     .media-element-container {
       @include span(6 of 12);
     }

      .side-text {
        @include span(6 of 12 last);

        h2 {
          margin-top: 0;
        }

        ul li {
          margin-bottom: $medium-margin-buffer;
        }
      }
    }
  }

  .side-view {
    @include with-layout($tile-layout) {
      .media-element-container:nth-child(2) img {
        margin-top: $medium-margin-buffer;
      }

      @include breakpoint($tablet-portrait) {

        h2 {
          margin-top: 0;
        }

        .media-element-container {
          @include span(6 of 12);
          margin: 0 25px 25px 0;
        }
      }
    }
  }

  .image-grid {
    @include with-layout($tile-layout) {
      overflow: hidden;
      margin-bottom: $large-margin-buffer;

      .media-element-container:nth-child(n+2) {
        margin-top: gutter();
      }

      @include breakpoint($tablet-portrait) {
        .media-element-container {
          @include span(6 of 12);
        }

        .media-element-container:nth-child(n+2) {
          margin-top: 0;
        }

        .media-element-container:nth-child(n+3) {
          margin: gutter() auto 0 auto;
        }

        .media-element-container:nth-child(even) {
          @include last;
        }
      }
    }

    @include breakpoint($tablet-landscape) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.node-718 {
  #lake-flato-glue-profile-contact-form--2 {
    margin-bottom: 50px;

    @media(min-width: $tablet-portrait) {
      width: 50%;
    }
  }
}

//Specific styling for recognition page
.node-33 {
  .awards-title {
    margin: 40px 0 16px 0;
    font-size: 16px;
  }

  .recipient {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .profile-contact__trigger {
    cursor: pointer;
    transition: color 0.2s;
    margin-bottom: 36px;
  }

  .profile-contact__trigger:hover {
    color: $hover-orange !important;
  }


  .profile-contact {
    display: none;

    input,
    textarea {
      padding: 10px;
    }

    input:focus,
    textarea:focus {
      outline-color: $orange;
    }
  }

  .profile-contact .form-submit {
    border-radius: 0;
    padding: 10px 14px 6px;
    font-size: 1rem;
  }

}

//Specific styling for contact us page
.node-32 {
  h3 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
  }

  #map-canvas {
    margin: 0;
    padding: 0;
    height: 635px;
    @include breakpoint($tablet-portrait) {
      @include span(8 of 12);
    }
  }

  .map-and-contact-container {
    overflow: hidden;
    margin: 3rem 0;
  }

  .office-map-image {
    @include breakpoint($tablet-portrait) {
      @include span(8 of 12);
    }
  }

  .side-info-area {
    margin-top: 2rem;
    @include span(10 of 12);

    @include breakpoint($mobile-landscape) {
      @include span(11 of 12);
    }

    @include breakpoint($tablet-portrait) {
      @include span(3.5 of 12 last);
      margin: 0;
    }
  }

  .address {
    .title {
      font-weight: 400;
    }
  }

  .physical-address {
    margin: 1rem 0 0 0;
  }

  .numbers {
    margin: 0 0 1rem 0;
  }

  .contact-title,
  .contact-link {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }

  .contact {
    a {
      margin-top: 0;
    }
  }

  .tours {
    margin-top: 1rem;
  }

  .tours__title {
    margin-bottom: 0.5rem;
  }

  .tours__item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .tours__icon {
    flex: 0 0 24px;
    width: 24px;
  }

  .tours__link {
    flex: 0 0 auto;
    margin: 0 0 0 5px;
  }
}

//Specific styling for our process/our culture/1% initiative page
.node-22,
.node-25,
.node-586 {

  .flexslider .file-image img {
    position: static;
  }

  .field-type-text-with-summary p:first-of-type {
    margin-bottom: $large-margin-buffer;
  }

  .file-image {
    position: relative;

    .field-name-field-file-image-title-text {
      position: absolute;
      z-index: 5;
      bottom: 8px;
      left: 16px;
      font-weight: 300;
      color: $white;
    }
  }

  li.field-item:before {
    z-index: 5;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @include box-shadow-generator;
  }
}

//careers page
.node-23 {
  ul {
    margin-bottom: $large-margin-buffer;
  }

  .jump-point:before {
    display: block;
    content: " ";
    margin-top: -100px;
    height: 100px;
    visibility: hidden;
  }
}

//internship page
.node-24 {
  ul {
    margin-bottom: $large-margin-buffer;
  }
}

// news
.node-34 {
  position: relative;
}

//download link styling
.field-name-field-file-download {
  position: relative;
  margin: $large-margin-buffer 0;

  &:hover {
    .download-icon {
      opacity: 0.8;
    }
  }

  .download-icon {
    width: auto;
    position: absolute;
    transition: opacity 0.2s;
  }

  .file-download-link {
    position: relative;
    padding-left: 30px;
  }
}

//video page
.node-12 {
  .profile-views-container {
    margin-right: 0;
    margin-left: 0;
  }
}

.node-brochure.view-mode-full .field,
.node-research-publication.view-mode-full .field {
  margin-bottom: 20px;
}

.node-brochure.view-mode-full .field-name-field-key-words .field-label,
.node-research-publication.view-mode-full .field-name-field-key-words .field-label {
  font-weight: 500;
  font-size: 1.2rem;
  color: rgb(64, 64, 65);
}

.node-brochure.view-mode-full .field-name-field-image,
.node-research-publication.view-mode-full .field-name-field-image-research {
  position: relative;
  display: inline-block;
}
