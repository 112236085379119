a.special-link {
  position: relative;
  display: block;
  padding-left: 45px;
  font-family: $din;
}

.special-link-container {
  margin-bottom: 10px;

  &:before {
    position: absolute;
    font-family: "icomoon";
    color: $white;
    line-height: 1.5rem;
    font-size: 1.5rem;
    background: $orange;
    transition: all 0.2s;
  }
}

.special-link-container.video,
.special-link-container.pdf,
.special-link-container.brochure {
  &:hover {
    &:before {
      background: lighten($orange, 10%);
    }
  }
}

.brochure:before {
  content: "\e903";
  padding-top: 1px;
  background: none;
  color: $white;
  background-color: $orange;
}

.video:before {
  content: "\e614";
}

.pdf:before {
  content: "\e602";
}