body.page-taxonomy-term #content {
  max-width: none;
  margin: 0;
}

.view-projects {
  .profile-views-container {
    max-width: 1200px;
    @include responsive-gutters();
  }

  .toggle-view {
    @include breakpoint($desktop) {
      margin: 0;
    }
  }

  .view-content {
    margin-bottom: $large-margin-buffer;
    border-top: 1px solid $grey;
    padding-top: 40px;
  }

  #taxonomy-term-8 {
    .file-video {
      @include breakpoint($tablet-portrait) {
        video {
          bottom: 0;
        }
      }
    }
  }

  .taxonomy-term {
    h2 {
      margin-top: 0;
      font-size: 1.6rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    .file {
      @include breakpoint($mobile-only) {
        padding-bottom: 75%;
      }

      img,
      video {
        @include breakpoint($mobile-only) {
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          @include translateX(-50%);
          max-width: none;
          height: 100%;
          width: auto;
        }
      }
      video::-webkit-media-controls-enclosure {
        display: none !important;
      }
    }

    .file-video {
      @include breakpoint($tablet-portrait) {
        padding-bottom: 41%; // this value matches the hero aspect ratio
        position: relative;
        video {
          display: block;
          position: absolute;
          bottom: -10%;
        }
      }
    }

    .contextual-links-region {
      position: static;
    }

    .content {
      @include clearfix;
    }

    .profile-views-container {
      margin-bottom: 15px;
    }

    .field-name-field-projects-media {
      position: relative;
      overflow: hidden;
      video {
        @include breakpoint($tablet-portrait) {
          width: 100%;
        }
      }
    }

    .field-name-field-file-image-title-text {
      margin-left: gutter();
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      color: $white;
      max-width: 1200px;

      @include breakpoint($desktop) {
        margin: 0 auto;
      }
    }
  }
}