.view-profiles,
.view-video,
.view-projects,
.view-research-publications,
.view-topics,
.view-edi,
.view-brochures {
  #project-tile-container { //Used as a location to bring user back to project tiles from project breadcrumb, so they are not taking to top of page
    &:before { //This little trick offsets it for fixed header
      display: block;
      content: " ";
      height: 100px;
      margin-top: -100px;
      visibility: hidden;
    }
  }
  .view-content {
    overflow: hidden;
    position: relative;
  }

  .view-content > div {
    margin: gutter() 0;

    @include breakpoint($mobile-landscape-only) {
      @include with-layout($tile-layout) {
        margin: gutter() 0 0 0;
        @include span(6 of 12);

        &:nth-child(2n) {
          @include last;
        }

        &:nth-child(-n+2) {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }

    @include breakpoint($tablet-portrait) {
      @include with-layout($tile-layout) {
        margin: gutter() 0 0 0;
        @include span(4 of 12);

        &:nth-child(3n) {
          @include last;
        }

        &:nth-child(-n+3) {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
  }
}

.view-brochures,
.view-research-publications {
  margin-top: 2em;
}

.node-profile .view-related-media {
  table.views-view-grid {
    width: 100%;
    margin: 0;
  }

  .view-content a {
    display: block;
    padding: 0.5rem 0.5rem 0 0;
  }

  .row-1,
  .row-2,
  .row-3 {

    .col-1,
    .col-2,
    .col-3 {
      float: left;
      clear: both;
      display: block;
    }
  }

  @include breakpoint($tablet-portrait) {
    .row-1,
    .row-2,
    .row-3 {
      float: left;
      @include span(4 of 12);
    }

    .row-3 {
      @include last;
    }
  }
}

.view-news,
.view-news-all {
  @include breakpoint($tablet-portrait) {
    @include span(8 of 12);
  }

  .view-filters {
    display: none;
  }

  .views-row {
    margin-top: $large-margin-buffer;
    &:first-child {
      margin-top: 0;
    }
  }
}

#ajax-link-profile-views {
  display: block;
  position: relative;
  padding-top: 30px;
  text-align: center;
  color: $grey;
  @include transition(all 0.3s ease);
  &:focus {
    outline: none;
  }
  &:hover {
    color: $orange;
  }
  &:before {
    position: absolute;
    transform: translate(0,-100%);
    left: 0;
    right: 0;
    font-size: 30px;
    font-family: 'icomoon';
    content: "\e608";
    line-height: 1em;
  }
}

//Shadow overlay on project tiles on taxonomy landing page view
.node-project.view-mode-tile {
  .file-image:before {
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @include box-shadow-generator;
  }
}

.views_infinite_scroll-ajax-loader img {
  display: inline-block;
  width: 3%;
}

.view-awards {
  margin-bottom: 50px;

  .views-field {
    padding: 10px 0;
  }

  .views-row,
  .view-header {
    display: flex;
  }

  .view-header h3 {
    display: inline;
    border-bottom: 2px solid $orange;
    padding-bottom: 5px;
    font-weight: 400;
    font-size: 16px;

    @media(min-width: 768px) {
      font-size: 1em;
    }
  }

  .header-year,
  .views-field-field-year {
    flex: 0 1 15%;

    @media(min-width: 768px) {
      flex: 0 1 10%;
    }
  }

  .header-name,
  .views-field-field-award-title-link {
    flex: 0 1 50%;

    @media(min-width: 768px) {
      flex: 0 1 50%;
    }
  }

  .header-project,
  .views-field-title {
    flex: 1 1 35%;

    @media(min-width: 768px) {
      flex: 1 1 30%;
    }
  }

  .item-list h3 {
    display: none;
  }

  fieldset {
    margin: 0;
    padding: 25px 0;
    border: none !important;
    border-bottom: 1px solid #c4c2c2 !important;
  }

  fieldset:last-child {
    border: none !important;
  }

  .fieldset-wrapper > a {
    display: none;
  }

  .default-open-years {
    h3,
    .fieldset-legend {
      display: none;
    }
  }

  .fieldset-legend {
    padding: 0 !important;
  }

  .fieldset-legend {
    margin-top: 17px;
  }

  .collapsed {
    padding: 20px 0;
  }
}

.view-topics .view-content,
.view-edi .view-content, {
  clear: both;
}

.view-topics,
.view-edi {
  margin-bottom: 2em;
}

.view-blog,
.view-blog-archives,
.view-blog-popular {
  .views-row {
    margin-bottom: 80px;
  }
}

.view-blog-popular.view-display-id-block_1 {
  .views-row {
    margin-bottom: 0;
  }
}
