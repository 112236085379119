body.node-type-project {
  .field-name-body p {
    margin-bottom: 20px;
  }
  .messages {
    position: absolute;
    width: 100%;
    top: 110px;
    z-index: 3;
    margin: 0;
  }
  //Keeps edit tabs below, since images are fixed to the bottom, they would otherwise be invisible
  .tabs-primary {
    z-index: 9;
    position: absolute;
    top: 65px;
    left: 0;
  }
  #content {
    max-width: none;
    margin: 0;
  }
}

//Flexslider CSS
.node-project.view-mode-full {
  .field-name-field-mobile-hero-image {

    @media(min-width: $tablet-portrait) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .flex-control-nav {
    display: none;

    @include breakpoint($desktop) {
      display: block;
    }
  }

  .slider-container {
    margin-bottom: $small-margin-buffer;

    .field-name-field-project-media {
      display: none;

      @media(min-width: $tablet-portrait) {
        display: block;
      }
    }

    .field-item {
      @media(min-width: $tablet-portrait) {
        &:before {
          z-index: 0;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          @include box-shadow-generator;
        }
      }
    }

    .flexslider {
      margin-bottom: 0;
    }

    .slide-info-container {
      display: block;
      width: 80%;
      font-size: 0;
      z-index: 12;
      margin-left: gutter();
      position: absolute;
      bottom: 20px;
      left: 0;
      color: $white;
      line-height: 0.8rem;

      @include breakpoint($desktop) {
        width: 40%;
        bottom: 8px;
      }

      span,
      a {
        font-size: 12px;
        padding: 0 5px;

        @include breakpoint($tablet-portrait) {
          font-size: 16px;
        }
      }

      .project-type {
        color: $orange;
        padding-left: 0;
        padding-right: 7px;
        transition: color 0.2s;

        &:hover {
          color: $hover-orange;
        }
      }

      .of-text {
        display: none;

        @include breakpoint($tablet-portrait) {
          display: inline;
          padding: 0 3px;
        }
      }

      .project-title {
        position: relative;
        padding-right: 7px;

        &:before {
          content: '|';
          position: absolute;
          left: -2px;

          @include breakpoint($tablet-portrait) {
            left: -3px;
          }
        }
      }

      .total-slides {
        display: none;

        @include breakpoint($tablet-portrait) {
          display: inline;
          padding-left: 0;
        }
      }

      .current-slide {
        display: none;

        @include breakpoint($tablet-portrait) {
          display: inline;
          padding-right: 0;
          position: relative;
        }

        &:before {
          content: '|';
          position: absolute;
          left: -2px;

          @include breakpoint($tablet-portrait) {
            left: -3px;
          }
        }
      }
    }

    .file {
      &.full-screen-portrait,
      &.full-screen-landscape {
        position: absolute;
        img,
        video {
          position: static;
          max-width: none;
        }
      }
      &.full-screen-portrait {
        width: auto;
        height: 100%;
        transform: translateX(-50%);
        top: 0;
        left: 50%;

        img,
        video {
          width: auto;
          height: 100%;
        }
      }
      &.full-screen-landscape {
        width: 100%;
        height: auto;
        transform: translateY(-50%);
        top: 50%;
        img,
        video {
          width: 100%;
          height: auto;
        }
      }
    }

    .hover-target-area {
      display: none;

      @media(min-width: $tablet-landscape) {
        display: block;
        position: absolute;
        z-index: 12;
        bottom: 0;
        right: 0;
        width: 25%;
        height: 15%;
        cursor: pointer;
      }
    }

    .project-helper-text {
      display: none; //No hover on mobile, so don't allow until tablet-portrait
      position: absolute;
      z-index: 11;
      bottom: 10px;
      right: 85px;
      color: $white;

      @include breakpoint($tablet-portrait) {
        display: block;
      }
    }

    .expand-drawer-icon {
      position: absolute;
      z-index: 12;
      bottom: 0;
      right: 24px;
      line-height: 3rem;
      font-family: "icomoon";
      background-color: $orange;
      color: $white;
      font-size: 3rem;
      cursor: pointer;
      transition: background-color 0.2s;

      &:before {
        content: "\e613";
      }

      &:hover {
        background-color: $hover-orange;
      }
    }
  }
}
