.node-34 {
  @include breakpoint($desktop) {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }

  .content-wrapper {
    margin-bottom: 0;
  }

  header .node-title {
    margin-bottom: $medium-margin-buffer;
  }

  //"Active" class for news filter so user knows which month they are on
  .news-filters li a.is-active-filter {
    color: $orange;
  }

  .news-filters {
    margin-top: $medium-margin-buffer;

    h4 {
      margin-bottom: 5px;
      font-size: 1rem;
      font-weight: 400;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
    }

    li {
      color: $grey;
      font-size: 1rem;
      font-weight: 300;
      cursor: pointer;
      transition: color 0.2s;
      line-height: 1.75em;

      a:hover {
        color: $orange;
      }
    }

    h4 {
      margin-top: 0;
    }

    //Custom styling for jQuery UI Accordian for news-filters
    .ui-accordion-header {
      width: 150px;
      padding: 8px 20px;
      border-radius: 0;
      background: none;
      background-color: $grey;
      border: none;
      color: $white;
      transition: background-color 0.2s;

      &:hover {
        background-color: $orange;
      }

      &:before { //sets the default icon
        font-family: 'icomoon';
        content: '\e613';
        position: absolute;
        top: 8px;
        right: 0.5em;
      }

      &.ui-state-active { //Sets the selected icon
        &:before {
          content: '\e60c';
        }
      }

      .ui-icon {
        right: .5em;
        left: auto;
        color: $white;
      }
    }

    .ui-accordion-content {
      border: none;

      a {
        color: $grey;
      }
    }

    @include breakpoint($tablet-portrait) {
      margin-top: 0;
      position: absolute;
      top: 96px;
      right: 0;
    }
  }
}

.node-news.node-teaser {
  .read-more {
    float: left;
    clear: left;
    color: $grey;
    margin-bottom: $large-margin-buffer;
  }

  .node__title.node-title {
    margin: 32px 0 8px 0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: none;
  }

    //Specific news styling overrides main p and body margins
  .field-name-body {
    width: 100%;
    margin: $medium-margin-buffer 0;
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
    }
  }

  .field-name-field-news-image {
    margin-bottom: $medium-margin-buffer;
  }

  .field-name-field-file-image-title-text {
    position: absolute;
    left: 16px;
    bottom: 8px;
    color: $white;
    font-weight: 300;
  }
}

.newsletter-column {
  display: none;

  @include breakpoint($tablet-portrait) {
    @include span(3.5 of 12 last);
    display: block;
    padding-left: 20px;
    border-left: 2px solid $orange;
  }

  h3 {
    color: $grey;
    font-weight: 400;
  }

  a {
    font-size: 14px;
  }

  .views-field-field-newsletter-link {
    margin-bottom: 10px;
    line-height: 1;
  }

  .view-content {
    margin-bottom: 30px;
  }

  .title {
    margin: 0 0 30px 0;
  }

  .view-content {
    h3 {
      margin: 30px 0 10px 0;
    }
  }
}