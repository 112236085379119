/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

/* Import Sass mixins, variables, Compass modules, etc. */
@import "init";

/* HTML element (SMACSS base) rules */
@import "normalize";
@import "page";

// /* Component (SMACSS module) rules */
@import "components/misc";
@import "components/header";
@import "components/mobile-menu";
@import "components/footer";
@import "components/basic-page";
@import "components/project";
@import "components/project-drawer";
@import "components/tile";
@import "components/profile-drawer";
@import "components/video-modal";
@import "components/list-view";
@import "components/video";
@import "components/views";
@import "components/taxonomy-project";
@import "components/homepage";
@import "components/flexslider";
@import "components/news";
@import "components/search-results";
@import "components/ckeditor_styles";
@import "components/custom-video-controls";
@import "components/porch-house";
@import "components/posts";

// Optionally, add your own components here.
@import "print";

