body.front {
  #page {
    overflow: visible;
  }

  img {
    display: block;
  }

  .hero-container {
    overflow: hidden;
    position: relative;
  }
  //Specific flexslider styles for homepage
  .flexslider {
    margin: 0;
    border: none;

    &:before {
      box-shadow: none;
    }
  }

  .flex-direction-nav a {
    top: 33%; //Keeps arrows centered as container changed size due to overlay being moved below it

    @include breakpoint($mobile-landscape) {
      top: 53%;
    }

    @include breakpoint($tablet-portrait) {
      top: 50%;
    }
  }

  .flex-control-nav {
    display: none;

    @include breakpoint($desktop) {
      display: block;
      left: 50%;
      width: auto;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  .opacity-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .node-carousel-item:before {
    z-index: 3;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    @include box-shadow-generator;
  }

  .multiple-video-container {
    .field-name-field-mobile-video {
      @media(min-width: $mobile-landscape) {
        display: none;
      }
    }

    .field-name-field-carousel-video {
      display: none;

      @media(min-width: $mobile-landscape) {
        display: block;
      }
    }
  }

  .node-carousel-item video {
    height: 100%;
    width: 177.77777778vh;
    min-width: 100%;
    min-height: 56.25vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @media(min-width: $mobile-landscape) {
      position: static;
      transform: unset;
      width: auto;
      height: 100%;
    }
  }

  .field-name-field-carousel-video .field-item,
  .field-name-field-mobile-video .field-item{
    height: 45vh;
    margin: 0;

    @media(min-width: $mobile-landscape) {
      height: 100vh;
    }
  }

  //Hide img on mobile landscape and above, show below
  .flexslider .field-name-field-carousel-item-media {
    @include breakpoint($mobile-landscape) {
      display: none;
    }
  }

  .flexslider .slider-background-image {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-bottom: 90%;

    @include breakpoint($mobile-landscape) { //height is set by JS to viewport height. VH units remain to help take up space
      display: block;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      padding: 0;
    }

    @include breakpoint($tablet-portrait) {
      background-position: center 70%;
      margin-top: -64px;
      padding: 0;
    }
  }

  .overlay {
    @include breakpoint($tablet-portrait) {
      z-index: 20;
      color: white;
      position: absolute;
      top: 135px;
      left: 0;
      right: 0;
    }

    //Treating these all the same for now. Sometimes they can un-hide the title and use that instead of the body, so want that to appear uniform
    p,
    a,
    h2 {
      margin: 0;
      line-height: 1.4em;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.6);
      font-size: 26px;
      font-family: $din;
      font-weight: normal;
    }

    a {
      display: block;
      font-weight: normal;
    }

    h2 {
      display: none;
      font-size: 1.7rem;
      padding-left: 1rem;

      @include breakpoint($tablet-portrait) {
        display: block;
      }
    }

    .overlay-inner-wrapper {
      @include responsive-gutters;
      position: relative;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      animation-delay: 1s;
    }

    .field-name-body.field-type-text-with-summary { //Hide quote on mobile, show on tablet
      display: none;
      width: 70%;

      @include breakpoint($tablet-portrait) {
        display: block;
      }

      .quote-author {
        margin-top: 20px;
        font-family: $din;
        font-size: 1.1rem;
        font-weight: 300;
        letter-spacing: 1px;
      }
    }
  }

  .node-title {
    margin: 16px 0 0 0;

    @include breakpoint($tablet-portrait) {
      margin: 0;
    }

    a {
      color: black;

      @include breakpoint($tablet-portrait) {
        color: white;
      }
    }

    h2 {
      margin: 0;
    }
  }

  .field-name-field-callout-label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 10px 10px 8px 10px;
    background: grey;
    color: $white;
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 600;

    @media(min-width: $tablet-portrait) {
      top: 64px;
      max-width: 75%;
      font-size: 1.7rem;
      padding: 25px 25px 20px 25px;
    }

    @media(min-width: $tablet-landscape) {
      max-width: unset;
    }
  }

  .slide-info-container {
    position: absolute;
    bottom: 10px;
    left: 0;
    margin-left: gutter();
    z-index: 3;
    color: $white;
    transition: all 0.7s;

    span {
      float: left;
      margin: 0 0 0 5px;
    }

    .counter-container {
      float: left;
    }
  }

  .field-name-field-carousel-item-link {
    float: left;

    a {
      position: relative;
      z-index: 50;
      font-weight: 300;
      color: $white;
      padding-left: 30px;
      @include transition(padding ease-in-out 0.4s);

      @include breakpoint($tablet-portrait) {
        padding: 0;
      }

      &:hover {
        padding-left: 30px;

        &:before {
          top: -5px;
          font-size: 30px;
          opacity: 1;
        }
      }

      &:before {
        position: absolute;
        top: -5px;
        left: 0;
        font-size: 30px;
        opacity: 1;
        content: '\e601';
        font-family: 'icomoon';
        color: $orange;
        font-weight: normal;
        @include transition(opacity 0.2s, top ease-in 0.3s);

        @include breakpoint($tablet-portrait) {
          opacity: 0;
          top: 15px;
        }
      }
    }
  }

  .hero-image {
    display: block;
  }

  .down-icon {
    display: none;
    z-index: 11;
    position: absolute;
    bottom: 208px;
    right: 48px;
    font-family: "icomoon";
    background-color: $orange;
    color: $white;
    font-size: 3rem;
    line-height: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:before {
      content: '\e60e';
    }

    &:hover {
      background-color: $hover-orange;
    }

    @include breakpoint($mobile-landscape) {
      bottom: 0;
      display: block;
    }
  }

  .homepage-view-area {
    overflow: hidden;
    margin-bottom: $large-margin-buffer;

    .instagram,
    .dogrun {
      margin-top: gutter();
      width: 100%;

      @include breakpoint($tablet-portrait) {
        margin-top: 0;
      }
    }

    .instagram {
      display: block;
    }

    .brochure-container,
    .instagram-container {
      overflow: hidden;
      position: relative;
      display: block;

      &:hover,
      &.hover {
        .hover-overlay {
          opacity: 0.8;
        }
      }
    }

    .dogrun-title,
    .instagram-title {
      z-index: 4;
      position: absolute;
      bottom: 4px;
      left: 8px;
      font-weight: 300;
      line-height: 1em;
      color: white;
      text-transform: capitalize;
    }

    //overlay for instagram tiles (which aren't actually views)
    .brochure-container .hover-overlay,
    .instagram-container .hover-overlay {
      @include breakpoint($tablet-portrait-only) {
        height: 50%;
      }

      position: absolute;
      z-index: 4;
      height: 35%;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $orange;
      opacity: 0;
      transition: opacity 0.2s;

      .view-overlay-text {
        position: absolute;
        font-size: 16px;
        top: 3px;
        left: 8px;
        color: $white;
        font-weight: 300;
      }
    }

    @include with-layout($homepage-layout) {
      .left-column {
        overflow: hidden;

        @include breakpoint($tablet-portrait) {
          @include span(6);
        }

        //Hide contextual links for editing the view on homepage "Featured" area
        .contextual-links-wrapper {
          display: none;
        }

        .file-video:before {
          display: none;
        }
      }

      .one-column {
        overflow: hidden;

        @include breakpoint($tablet-portrait) {
          @include span(3);
          @include gutters(5px);
          .brochure-container,
          .instagram-container {
            margin-top: 5px;
          }
        }

        @include breakpoint($tablet-landscape) {
          .brochure-container,
          .instagram-container {
            margin-top: 8px;
          }
        }
      }

      .last {
        @include breakpoint($tablet-portrait) {
          @include last;
        }
      }
    }
  }

  //Homepage specific view styling
  .view-random-item {
    margin: gutter() 0 0 0;

    .node-title {
      text-transform: capitalize;
      left: 0;
    }

    //Hide contextual links for editing the view on homepage "Featured" area
    .contextual-links-wrapper {
      display: none;
    }

    @include breakpoint($tablet-portrait) {
      margin: 0;
    }
  }

  //box-shadow for homepage tile views (and the 2 that actually aren't views, but still tiles)
  .homepage-view-area .one-column {

    .view-image-container {
      position: relative;
    }

    .view-image-container:before,
    .view-random-item .file-image:before {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      @include box-shadow-generator;
    }
  }

  .node > a {
    display: block;
    position: relative;
    z-index: 2;
  }

  .hover-target-area {
    position: absolute;
    z-index: 12;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 15%;
  }

  .project-helper-text {
    display: none; //No hover on mobile, so don't allow until tablet-portrait
    position: absolute;
    z-index: 11;
    bottom: 0;
    right: 105px;
    opacity: 0;
    color: $white;
    transition: all 0.2s;

    @include breakpoint($tablet-portrait) {
      display: block;
    }
  }

  .hover-target-area:hover .project-helper-text,
  .expand-drawer-icon:hover .project-helper-text {
    opacity: 1;
    bottom: 10px;
  }

  .expand-drawer-icon {
    position: absolute;
    z-index: 12;
    bottom: 0;
    right: 24px;
    line-height: 3rem;
    font-family: "icomoon";
    background-color: $orange;
    color: $white;
    font-size: 3rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:before {
      content: "\e613";
    }

    &:hover {
      background-color: $hover-orange;
    }
  }

  //Temporary custom styling for 1st slider banner for campaign on climate change
  #node-49 {
    .overlay .field-name-body.field-type-text-with-summary {
      width: 100%;

      .architect-advocate-banner {
        display: flex;
        flex-wrap: wrap;
        width: 90%;

        .image {
          flex: 0 0 350px;
          margin: 0 35px 25px 0;
        }

        .text {
          flex: 1 1 90%;

          @include breakpoint($tablet-landscape) {
            flex: 1 1 50%;
          }

          p {
            font-size: 18px;
            text-transform: none;
            letter-spacing: 0;
            color: white;
          }

          a {
            text-decoration: underline;
            font-size: 18px;
            text-transform: none;
            letter-spacing: 0;
            font-style: italic;
            color: white;
          }
        }
      }
    }
  }
}

