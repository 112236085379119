$header-height-mobile: 64px;
$header-height-desktop: 64px;
$porch-house-menu-height-desktop: 128px;

.header {
  @include legacy-pie-clearfix;
}

.fixed-header-wrapper {
  height: $header-height-mobile;
}

.page--porch-house {
  .fixed-header-wrapper,
  .fixed-header {
    @include breakpoint($tablet-portrait) {
      height: $header-height-desktop + $porch-house-menu-height-desktop;
    }
  }
}

// take up the space in document flow that the header should be occupying on top of the page - MEA
.fixed-header {
  height: $header-height-mobile;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  z-index: 13;
  background-color: $white;
  box-sizing: border-box;
  @include breakpoint($tablet-portrait) {
    padding: 20px 0;
    height: $header-height-desktop;
  }
}

.header__region {
  font-weight: 300;

  .nav-search {
    position: absolute;
    right: 8px;
    top: -1px;
    padding: 1.2rem 0 1rem;
    color: grey;
    cursor: pointer;
    transition: color 0.2s;
    font-family: 'icomoon';

    &:before {
      content: '\e607';
    }

    &:hover {
      color: $orange;
    }

    @include breakpoint($tablet-portrait) {
      right: -5px;
      top: -23px;
      font-size: 29px;
    }
  }

  .block-menu-block {
    @include breakpoint($tablet-portrait) {
      float: right;
      margin-bottom: 0;
      position: relative;
      display: inline-block;
    }
  }

  .hero-container {
    position: relative;
    overflow: hidden;
    clear: both;

    .field-name-field-page-hero-media {
      img {
        @include breakpoint($mobile-only) {
          position: absolute;
          top: 0;
          max-width: none;
          left: -50%;
          height: 100%;
          width: auto;
        }

        width: 100%;
        display: block;
      }

      .file-image {
        position: relative;
        @include breakpoint($mobile-only) {
          padding-bottom: 75%;
        }
        > a {
          display: block;
        }
      }
      .field-type-link-field {
        color: $white;
        @extend .max-width;
        @include breakpoint($desktop) {
          position: relative;
        }
        .field-items {
          position: absolute;
          bottom: 0;
          left: 0;
          @include responsive-gutters;
        }
      }
    }
  }

  @include breakpoint($tablet-portrait) {
    @include legacy-pie-clearfix;
    margin-bottom: 0;
    margin-top: 0;
    position: relative;

    //search bar in header
    .block-search {
      @include push(1);
      display: none;
      position: absolute;
      left: -40px;
      width: 100%;
      top: 130px;
      color: $transparent-white;
      font-size: 0;

      form {
        position: relative;
        z-index: 5;
      }

      label {
        position: static !important;
        clip: auto;
        padding-bottom: 8px;
        padding-right: 10px;
        margin-right: -3px;
        font-size: 15px;
        font-weight: 300;
        border-bottom: 2px solid $transparent-white;
        border-radius: 0;
        display: inline-block;
        vertical-align: middle;
        width: 60px;
        box-sizing: border-box;
        line-height: 1em;
        height: 24px;
      }

      input:not([type="submit"]) {
        margin: 0 auto;
        width: 85%;
        border: none;
        font-weight: 300;
        border-radius: 0;
        border-bottom: 2px solid $transparent-white;
        color: $white;
        background-color: $orange;
        outline: none;
        display: inline-block;
        font-size: 15px;
        padding-bottom: 8px;
        padding-top: 0;
        height: 25px;
        vertical-align: middle;

        @include breakpoint($tablet-landscape) {
          width: 90%;
        }
      }

      .form-item {
        display: block;
      }

      .container-inline {
        position: relative;
        top: -30px;
        left: -25px;

        @include breakpoint($tablet-landscape) {
          left: -50px;
        }
      }

      #edit-actions {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: -86px;
        z-index: 3;
        border-left: 2000px solid $orange;
        height: 204px;
        transform: translateX(-100%);
      }

      &::after {
        content: '';
        position: absolute;
        top: -86px;
        z-index: 3;
        border-left: 2000px solid $orange;
        height: 204px;
      }
    }
  }

  nav {
    display: none;

    @include breakpoint($tablet-portrait) {
      @include span(6 of 12 last);
      display: block;
      font-size: 1rem;

      ul,
      li {
        list-style: none !important;
        padding: 0;
      }

      a {
        text-decoration: none;
      }

      //Top menu level
      .menu-block-wrapper > ul {
        @include legacy-pie-clearfix;
        margin: 0;

        > li {
          float: left;
          position: relative;

          &.is-active-trail,
          &:hover {
            > a {
              color: $orange;
            }
          }

          > a {
            box-sizing: border-box;
            padding: 0 5px 20px 5px;
            display: block;
            color: $grey;
            transition: color 0.2s;
          }
        }
      }

      // hover treatment on menu items
      &:not(.has-active-search-block) {
        .menu-block-wrapper > ul > li:hover {
          > ul {
            display: block;
          }
        }

        .menu-block-wrapper > ul > li:first-of-type:hover {
          > ul {
            display: block;
          }
        }
      }

      //Second menu level
      .menu-block-wrapper > ul > li > ul {
        display: none;
        height: 188px;
        width: 500px;
        z-index: 6;
        position: absolute;
        top: 44px;
        left: 11px;
        padding: 0.5rem 0;
        background-color: $orange;

        a {
          padding: 0.5rem 0.5rem 0.5rem 0;
          color: white;
          transition: color 0.2s;
          &.is-active-trail,
          &:hover {
            color: $black;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          border-left: 2000px solid $orange;
          height: 204px;
          @include translateX(-100%);
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          z-index: 5;
          right: 0;
          border-left: 2000px solid $orange;
          height: 204px;
          transform: translateX(100%);
        }

        .subset1,
        .subset2 {
          margin-right: 1.5rem;
        }

        .subset1,
        .subset2,
        .subset3 {
          float: left;
        }
      }

      //Special case for menu with 3 column submenus
      .menu-block-wrapper > ul > li.menu-mlid-727 > ul {
        left: -140px;
      }
    }

    @include breakpoint($tablet-landscape) {
      @include span(6 of 12 last);
    }
  }

  .menu-wrapper {
    @include legacy-pie-clearfix;
    @include responsive-gutters;
    position: relative;
    z-index: 10;
    background-color: white;

    @include breakpoint($desktop) {
      max-width: 1200px;
      margin: 0 auto;
    }

    @include breakpoint($tablet-portrait) {
      padding-top: 0;
    }

    & > a {
      display: block;
      float: left;
      outline: none;
    }

    .flato-logo {
      max-width: 226px;
    }
  }

  .hamburger-menu {
    float: right;
    width: 23px;
    font-size: 1.6rem;
    color: $orange;
    cursor: pointer;
    font-family: 'icomoon';
    font-style: normal;

    &:before {
      content: '\e605';
    }

    @include breakpoint($tablet-portrait) {
      display: none;
    }
  }

  #mobile-menu {
    padding-right: 25px;
  }

  .menu-block-wrapper {
    @include legacy-pie-clearfix;
  }
  .porch-house-menu-wrapper-mobile {
    @include breakpoint($tablet-portrait) {
      display: none;
    }
  }
  .porch-house-menu-wrapper-desktop {
    display: none;
    @include breakpoint($tablet-portrait) {
      display: block;
    }
  }
  .menu-block--porch-house {
    @extend .max-width;
    display: block;
    float: none;
    text-align: center;
    margin-bottom: 0;
    @include breakpoint($mobile-only) {
      margin: 0;
    }
    .menu-block-wrapper {
      position: relative;
      padding: 0 0 17px;
      @include breakpoint($tablet-portrait) {
        padding: 45px 0;
        border-top: 1px solid rgb(151,151,151);
      }
      &.porch-house--mobile-show {
        .fa {
          transform: translateY(-2px) rotate(180deg);
        }
        ul {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          background: $white;
          margin: 7px 0 0;
          z-index: 1000;
          box-shadow: 50px 0 $white,
          -50px 0 white,
          0 -5px 0 $white,
          0 10px 15px rgba(0, 0, 0, 0.3);
        }
      }
    }
    ul {
      display: none;
      @include breakpoint($tablet-portrait) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: 0;
      }
    }
    li {
      display: block;
      flex: 0 0 1;
      list-style-type: none;
      margin: 7px 5px;
      @include breakpoint($tablet-portrait) {
        margin: 0 5px;
      }
      &.is-active-trail,
      &:hover {
        > a {
          color: $orange;
        }
      }
    }
    a {
      text-transform: uppercase;
    }
    .porch-house--logo--mobile {
      display: block;
      height: 26px;
      padding: 20px 0 0;
      border-top: 1px solid rgb(151,151,151);
      margin: 10px 20px 15px;
      @include breakpoint($tablet-portrait) {
        display: none;
      }
      img {
        width: 140px;
      }
    }
    .porch-house--mobile-trigger {
      text-transform: uppercase;
      color: $orange;
      @include breakpoint($tablet-portrait) {
        display: none;
      }
      .fa {
        font-size: 0.75rem;
        transform: translateY(-2px);
        transition: all 0.5s ease;
      }
    }
    .porch-house--logo--desktop {
      display: none;
      @include breakpoint($tablet-portrait) {
        display: block;
        flex: 0 0 1;
        height: 37px;
        margin: 0 35px;
      }
      @include breakpoint($desktop) {
        margin: 0 55px;
      }
    }
  }
}

