//SHARED
.node-blog-post {
  .node-title a {
    color: #e8ad42;
    text-transform: uppercase;
  }

  .blog__header {
    overflow: hidden;
  }

  .user-picture {
    float: left;
    margin-right: 15px;
  }

  .submitted-info {
    font-size: 14px;
  }

  .field-name-field-blog-image {
    margin-bottom: $medium-margin-buffer;
  }
}

// TEASER
.node-blog-post.node-teaser {
  .node__title.node-title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: none;
  }
}

// FULL PAGE
.node-blog-post.view-mode-full {
  margin-top: 50px;

  ul {
    list-style: disc;
    padding: 0 0 0 30px;
    margin: 1.5em 0;
  }

  ol {
    list-style: decimal;
  }

  li {
    list-style: unset;
  }

  .dogrun__title {
    margin-top: -20px;
    margin-bottom: 35px;
  }

  .title-container {
    display: block;
    grid-area: title;
    margin-bottom: 20px;
  }

  .title-container__title {
    margin-bottom: 0;
    color: $black;
    font-size: 1.9rem;
    font-weight: 500;
  }

  .title-container__subtitle {
    margin-top: 0;
    color: $black;
    letter-spacing: 0.6px;
  }

  .node__title {
    margin: 0;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.5rem;
    text-transform: none;
  }

  .field-name-field-tags {
    margin-bottom: 30px;

    .field-items {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .field-item {
      border: 1px solid $black;
      margin-right: 5px;
      padding: 0 5px;
      font-size: 14px;

      a {
        color: $black;
        text-transform: lowercase;
      }
    }
  }

  .field-name-body {
    margin-bottom: 25px;

    strong,
    img {
      display: block;
      margin: 10px 0;
    }

    div {
      float: none !important;
    }
  }

  .social {
    margin-bottom: 50px;
  }

  // LinkedIn Widget
  .IN-widget {
    vertical-align: top !important;
  }

  .back-button {
    cursor: pointer;
    margin-bottom: 25px;
    color: #404041;
    font-weight: 400;
    text-decoration: underline;
  }
}
