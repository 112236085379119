//
// Initialization partial
//
// To make it easier to use all variables and mixins in any Sass file in this
// theme, each .scss file has a @import "init" declaration. And this _init.scss
// file is in charge of importing all the other partials needed for the theme.
//
// This initialization partial is organized in this way:
// - First we set any shared Sass variables.
// - Next we import Compass plug-ins (Sass mixin libraries).
// - Last we define our custom mixins for this theme.
//


// =============================================================================
// Variables
// =============================================================================

//
// Font faces, stacks and sizes.
//

// Compass' vertical_rhythm extension is a powerful tool to set up a vertical
// rhythm for your entire page. You can see some of its mixins and functions in
// use in the normalize.scss file.
// @see http://compass-style.org/reference/compass/typography/vertical_rhythm/

$base-font-size:   16px; // The font size set on the root html element.
$base-line-height: 24px; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 20px;
$h2-font-size: 14px;
$h3-font-size: 1.6    * $base-font-size;
$h4-font-size: 1.6    * $base-font-size;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.


// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
//$courier:         "Courier New", "DejaVu Sans Mono", monospace, sans-serif;
$din: "DINNextLTPro", sans-serif;
$casion: "ACasionPro", serif;
$iconSet: "icomoon", sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $din; // The font family set on the html element.
$font-body:        $din;
$font-monospace:   $din;

// Colors, etc.
$orange: #e8ad42;
$hover-orange: lighten($orange, 10%);
$transparent-white: rgba(255,255,255,0.6);
$white: #ffffff;
$black: #404041;
$grey: #aaaaaa;
$solid-grey: grey;
$off-black: rgba(0, 0, 0, 0.85);

// The amount lists, blockquotes and comments are indented.
$indent-amount: 30px;

// The height of the navigation container.
$nav-height: 3em;

// Tab styling.
$tabs-container-bg: #fff;
$tabs-border: #bbb;

// Breakpoints
$mobile-portrait: 320px;
$mobile-landscape: 480px;
$tablet-portrait: 768px;
$hero-image-max: 1200px;
$tablet-landscape: 1024px;
$desktop: 1280px;
$header: 1126px;
$mobile-only: max-width $tablet-portrait - 1;
$mobile-landscape-only: $mobile-landscape $tablet-portrait - 1;
$tablet-portrait-only: $tablet-portrait $tablet-landscape - 1;
$tablet-landscape-only: $tablet-landscape $desktop - 1;
$tablet-only: $tablet-portrait $desktop - 1;
$tablet-landscape-and-below: max-width $desktop - 1;

//Specific Margins/Padding
$large-margin-buffer: 48px;
$medium-margin-buffer: 16px;
$small-margin-buffer: 8px;
$page-margin-mobile: 20px;
$page-margin-tablet-portrait: 36px;
$page-margin-tablet-landscape: 48px;
$profile-drawer-gutter-mobile: 20px;
$profile-drawer-gutter-tablet: 36px;

// Max Widths

//
// =============================================================================
// Partials to be shared with all .scss files.
// =============================================================================

// Susy requires full compass
@import "../node_modules/compass-mixins/lib/compass";
// Include Susy
@import "../node_modules/susy/sass/susy";

@import "../node_modules/breakpoint-sass/stylesheets/breakpoint";

// Now we add our custom helper mixins.
@import "mixins";

// =============================================================================
// Susy Config (added here because it needs to be below the susy import)
// =============================================================================

$susy: (
  gutter-position: after,
  columns: 12,
  gutters: 0.4
);

$tile-layout: (
        columns: 12,
        gutters: .1
);

$homepage-layout: (
        columns: 12,
        gutters: .1
);

//one span+one gutter, using to position timeline items
//has to be placed after susy init so putting it here
$spanGutter: span(1) + gutter();
